<template>
	<main v-show="show_main_vue">
		<HeaderTab :title="$t('mouvement.mouvements_residence')" return_path="/horse" />
		<div id="content">
			<div class="container-fluid">
				<div class="row">
					<div class="col-12">
						<div class="box">
							<div class="row">
								<div class="col-12" v-if="form_message !== ''">
									<ErrorAlert :messageI18n="form_message" />
								</div>
							</div>
							<div class="row">
								<div class="col-3">
									<label for="lieu">{{ $t('mouvement.lieu_mouvement') }}</label>
									<e-select
	                                    v-model="selected_lieux"
	                                    id="mouv_resid"
	                                    track-by="uuid"
	                                    label="name"
										multiple
	                                    :placeholder="labelTitle"
	                                    :deselectLabel="deselectLabel"
	                                    :selectedLabel="selectedLabel"
	                                    :selectLabel="enter_to_select"
	                                    :options="lieux"
	                                    :searchable="true"
	                                    :show-labels="false"
	                                    :allow-empty="false"
										:class="{ 'is-invalid': errors && errors.indexOf('Lieu error') > -1 }"
	                                >
										<template slot="option" slot-scope="props"><span :style="`padding-left: ${25 * (props.option.indent || 0) }px;`">{{ props.option.name }}</span></template>
	                                    <template slot="singleLabel" slot-scope="{ option }">{{ option.name }}</template>
	                                    <template slot="noOptions">{{ $t('global.list_empty') }}</template>
	                                </e-select>
								</div>
								<div class="col-9">
									<DateRangeV2
										:start.sync="date_debut"
										:end.sync="date_fin"
										:submitAtCreation="initLoading"
										@submit="checkForm"
										dateSelect
										:periodToShow="[ 'personalise', 'mois_dernier', 'mois', 'semaine_prochaine', 'semaine_derniere', 'semaine', 'mois_prochain']"
									/>
								</div>

								<!-- <div class="col">
									<label for="date">{{ $t('global.date_debut') }}</label>
									<e-datepicker id="date" v-model="date_debut" :class="{ 'is-invalid': errors && errors.indexOf('Date error') > -1 }"></e-datepicker>
								</div>

								<div class="col">
									<label for="date">{{ $t('global.date_fin') }}</label>
									<e-datepicker id="date_fin" v-model="date_fin" :class="{ 'is-invalid': errors && errors.indexOf('Date error') > -1 }"></e-datepicker>
								</div> -->
<!-- 
								<div class="col">
									<label>&nbsp;</label>
									<button class="btn btn-primary btn-block" @click.prevent="checkForm()">{{ $t('global.rechercher') }}</button>
								</div> -->
							</div>
							<div class="row" v-if="date_updated">
								<div class="col-12">
									<b-alert show variant="warning" class="mt-2">
										{{ $t('mouvement.cache_date') }} {{ date_updated }}, <a href="#" @click.prevent="forceReload">{{ $t('mouvement.ici') }}</a> {{ $t('mouvement.pour_rafraichir') }}
									</b-alert>
								</div>
							</div>
						</div>
						<div class="box">
							<CustomTable
								id_table="mouvements_residence"
								:items="mouvements"
								:busy.sync="table_busy"
                                :hrefsRoutes="config_table_hrefs"
								primaryKey="mouvement_id"
								:externSlotColumns="extern_slot_columns"
							>
							<template v-slot:[`custom-slot-cell(horse.horse_wholesire)`]="{ data }">
								<e-copybox :value="data.horse.horse_wholesire"></e-copybox>
							</template>
							</CustomTable>
						</div>

						<ModalPrintInfosHorse ref="modal_print_infos_horse"/>

						<b-modal ref="modal" hide-footer>
							<template v-slot:modal-title>
								<template>
									{{ $t("horse.ajouter_pension") }}
								</template>
							</template>
							
							<PensionAjout
								ref="PensionAjout"
								:horse_ids="horse_ids"
								:add-or-edit-ready="add_ready"
							/>

							<b-button class="mt-3 btn-block rounded-pill" block @click.prevent="checkFormPension" variant="primary"><font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse /> {{ $t("global.ajouter") }}</b-button>
						</b-modal>
					</div>
				</div>
			</div>
		</div>
	</main>
</template>


<script type="text/javascript">
	import Vue from 'vue'
	import Horse from "@/mixins/Horse.js"
	import IntraLocation from "@/mixins/IntraLocation.js"
	import Lieux from "@/mixins/Lieux.js"
	import Navigation from "@/mixins/Navigation.js"
    import Config from "@/mixins/Config.js"

	export default {
		name: "mouvementResidence",
		mixins: [Horse, IntraLocation, Lieux, Navigation, Config],
		data () {
			return {
				deselectLabel: this.getTrad("global.press_enter_to_remove"),
				selectedLabel: this.getTrad("global.selected_label"),
				enter_to_select: this.getTrad("global.press_enter_to_select"),
				labelTitle: this.getTrad("lieu.residences"),
				show_main_vue: true,
				lieux: [],
				selected_lieux: [],
				date_debut: new Date(),
				date_fin: new Date(),
				table_busy: false,
				form_message: '',
				mouvements: null,
				errors: [],
				date_updated: null,
				processing: false,
				horse_ids: null,
				add_ready: false,
				initLoading: false,
				extern_slot_columns: [
					'horse.horse_wholesire'
				],

				/* Nom du cheval cliquable dans le tableau */
                config_table_hrefs: {
                    'horse.horse_nom': {
                        routeUniqueName: 'horseFiche',
                        routeName: 'horseFiche',
                        params: {
                            horse_id: 'horse_id'
                        }
                    }
				},

				events_tab: {
					'TableAction::goToPrintInfosHorse': (params) => {
						this.openModalPrintInfosHorse(params.horse_ids)
					},
					'TableAction::DeleteHorses': this.deleteHorses,
					'TableAction::goToAddPension': (horse_ids) => {
						this.openModalAddPension(horse_ids)
					}
				},
			}
		},
		created() {
			this.prepareDates()
		},
		mounted() {

			this.init_component()
		},
		methods: {
			async init_component() {
				// On récupére les lieux et intraLocations sous le même format
				const lieux = await this.getIntraLocationsWithresidences(true, false) || []
				this.lieux = []
				let inc = 0
				
				lieux.forEach(lieu => {
					const temp = {
						id: lieu.intralocation_id,
						uuid: inc++,
						name: lieu.intralocation_label,
						indent: lieu.indent,
						is_lieu: false,
						default: lieu.intralocation_default
					}

					// Si c'est un lieu ou une localisation interne
					if(lieu.indent == 0) {
						temp.is_lieu = true
					}
					
					this.lieux.push(temp)
				})

				this.$nextTick(function () {
					if(this.$route.params.intralocation_id) {
						this.selected_lieux = this.lieux.filter(l => l.id == this.$route.params.intralocation_id)
					} 	
	                else if(this.getConfig('selectedLieuxResidence')) {
	                    this.selected_lieux = this.getConfig('selectedLieuxResidence')
	                }
					else if(this.lieux.filter(l => l.default).length == 1) {
						this.selected_lieux = this.lieux.filter(l => l.default)
					}
					else {
						this.selected_lieux = [this.lieux[0]]
					}

					this.checkForm(true)
				})
			},

			async checkForm(initLoad=false, forceLoad=false) {
				this.initLoading = initLoad
				this.errors = []

				this.form_message = ""

				if(this.selected_lieux.length === 0) {
					this.errors.push("Lieu error")
				}

				if(this.date_fin < this.date_debut) {
					this.errors.push("Date error")
				}

				if(this.errors.length == 0) {
					this.table_busy = true

					const lieux_ids = this.selected_lieux
						.filter(l => l.is_lieu === true)
						.map(l => l.id)

					const intraloc_ids = this.selected_lieux
						.filter(l => l.is_lieu === false)
						.map(l => l.id)

					let mouvements = await this.getHorseResidenceDate(lieux_ids, intraloc_ids, this.date_debut.toDateInputValue(), this.date_fin.toDateInputValue(), forceLoad)

					this.date_updated = null
					if(mouvements.date_updated) {
						const date = new Date(mouvements.date_updated)
						this.date_updated = date.toLocaleDateString(Vue.i18n.locale()) +  " à " + `0${date.getHours()}`.slice(-2) + "h" + `0${date.getMinutes()}`.slice(-2)
						mouvements = mouvements.data
					}

					mouvements.forEach(mouvement => {
						mouvement.proprietaires = mouvement.tiers_horse
							.map(th => {
								return `${th.tiers.tiers_rs} (${th.parts.tiershorsepart_propriete}%)`
							})
							.join(', ')
						if(mouvement.proprietaires == '') {
							mouvement.proprietaires = '-'
						}

						mouvement.pourcentage_pension = mouvement.tiers_horse
							.map(th => {
								return `${th.parts.tiershorsepart_pension}%`
							})
							.join(', ')

						mouvement.pourcentage_frais = mouvement.tiers_horse
							.map(th => {
								return `${th.parts.tiershorsepart_frais}%`
							})
							.join(', ')

						mouvement.pourcentage_contrats = mouvement.tiers_horse
							.map(th => {
								return `${th.parts.tiershorsepart_contract}%`
							})
							.join(', ')

						mouvement.contact = mouvement.contact ? `${mouvement.contact.contact_civility} ${mouvement.contact.contact_firstname} ${mouvement.contact.contact_lastname}` : ''
					})

					this.mouvements = mouvements

               		this.setConfig('selectedLieuxResidence', this.selected_lieux)

					this.table_busy = false
				}
				else {
					// S'il s'agit du chargement au montage du composant, on skip les erreurs
					if(initLoad) {
						this.initLoading = false
						return
					}
					this.form_message = "formulaire.erreur_champs_non_remplis"
					this.failureToast('formulaire.erreur_champs_non_remplis');
				}
				this.initLoading = false
			},

			async deleteHorses(mouvements) {
				const ids = mouvements.map(mouvement => mouvement.horse_id)

				this.devalideHorses(ids)
					.then(() => {
						this.checkForm()
					})
			},

			prepareDates() {
				this.date_debut.setHours(0)
				this.date_debut.setMinutes(0)
				this.date_debut.setSeconds(0)

				this.date_fin.setHours(23)
				this.date_fin.setMinutes(59)
				this.date_fin.setSeconds(59)
			},

			openModalPrintInfosHorse(horse_ids) {
				this.$refs.modal_print_infos_horse.openModal(horse_ids)
			},

			forceReload() {
				this.checkForm(false, true)
			},

			openModalAddPension(horse_ids) {
				this.horse_ids = horse_ids
				this.add_ready = true
				this.$refs.modal.show()
			},
			async checkFormPension() {
				if(!this.processing) {
					this.processing = true
					let result = await this.$refs.PensionAjout.checkForm()
					if(result) {
						this.$refs.modal.hide()
					}

					this.processing = false
				}
			}
		},
		components: {
			HeaderTab: () => import('@/components/HeaderTab'),
			CustomTable : () => import('GroomyRoot/components/Table/CustomTable'),
			ErrorAlert: () => import('GroomyRoot/components/Alert/ErrorAlert'),
			ModalPrintInfosHorse : () => import('@/components/Horse/ModalPrintInfosHorse'),
			PensionAjout : () => import('@/components/Horse/PensionAjout'),
			DateRangeV2: () => import('GroomyRoot/components/Inputs/DateRangeV2')
		}
	}
</script>
